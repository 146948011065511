import last from "lodash/last";
import merge from "lodash/merge";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { v5 as uuidv5 } from "uuid";
import { ProgressTracker } from "../../components";
import { useNavigator } from "../../hooks/index";
import DefaultLayout from "../../layouts/default-layout";
import { currentUserSelector } from "../../redux/selectors";
import { updateEmployee } from "../../services/api";
import { getItem, setItem, showFunVideo } from "../../utils";
import UserHijack from "~/components/super-admin/user-hijack";
import { UUID_NAMESPACE } from "~/constants";

const getTabsList = (t, isAlternatePayee) => [
  {
    id: "welcome",
    title: t("dashboard:dashboard.employee.onboarding.sideMenu.welcome"),
    steps: [{ id: "welcome", isVisited: false }],
    showInProgressTracker: false,
  },
  {
    id: "disclosures",
    title: t("dashboard:dashboard.employee.onboarding.sideMenu.disclosures"),
    steps: [{ id: "disclosures", isVisited: false }],
    showInProgressTracker: false,
  },
  {
    id: "personal",
    title: t("dashboard:dashboard.employee.onboarding.sideMenu.personalInfo"),
    steps: [
      { id: "employee", isVisited: false },
      { id: "additional", isVisited: false },
    ],
    showInProgressTracker: true,
  },
  {
    id: "beneficiaries",
    title: t("dashboard:dashboard.employee.onboarding.sideMenu.beneficiaries"),
    steps: [{ id: "beneficiaries", isVisited: false }],
    showInProgressTracker: true,
  },
  ...(isAlternatePayee
    ? []
    : [
        {
          id: "investment",
          title: t("dashboard:dashboard.employee.onboarding.sideMenu.investment"),
          steps: [{ id: "investment", isVisited: false }],
          showInProgressTracker: true,
        },
        {
          id: "funds",
          title: t("dashboard:dashboard.employee.onboarding.sideMenu.funds"),
          steps: [{ id: "funds", isVisited: false }],
          showInProgressTracker: true,
        },
      ]),

  {
    id: "nice-going",
    title: t("dashboard:dashboard.employee.onboarding.sideMenu.niceGoing"),
    steps: [{ id: "nice-going", isVisited: false }],
    showInProgressTracker: false,
  },
];

function EmployeeOnboarding({ basePath: path = "/individual/onboarding" }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const cleanUrl = location.pathname.split(path).join("");
  const [, tabId, stepId] = cleanUrl.split("/");
  const basePath = path.substr(1);
  const currentUser = useSelector(currentUserSelector);
  const queryClient = useQueryClient();
  const localStorageKey = useMemo(() => {
    return (
      "employee_tracks." +
      uuidv5(`${currentUser.id}`, UUID_NAMESPACE) +
      ".employee_tracks"
    );
  }, [currentUser]);

  const dispatch = useDispatch();
  const companyId = useMemo(() => currentUser.company_id, [currentUser]);
  const accountId = useMemo(() => currentUser.individual?.account.id, [currentUser]);
  const employeeId = useMemo(() => currentUser.individual?.employee.id, [currentUser]);
  const isAlternatePayee = !!currentUser.individual?.alternate_payee_of;

  const {
    tabs,
    setTabs,
    tabIndx,
    stepIndx,
    activeTab,
    activeStep,
    onBack,
    onForward,
    onContinue,
    progressCircleIndex,
  } = useNavigator({
    tabsArray: getTabsList(t, isAlternatePayee),
    tabId,
    stepId,
    basePath,
  });

  const isFirstRender = useRef(true);

  const isActiveTab = (tab) => tab.id === tabId || (!tabId && tab.id === tabs[0].id);

  const updateEmployeeMutation = useMutation({
    mutationFn: (data) => updateEmployee(companyId, employeeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["users", "me"]);
    },
  });

  const onNextStep = async (data, shouldSubmit = true, shouldContinue = true) => {
    if (shouldSubmit && employeeId) {
      await updateEmployeeMutation.mutateAsync(data);
      if (!shouldContinue) {
        return;
      }
    }
    onContinue();
  };

  useEffect(() => {
    const storedTabs = getItem(localStorageKey);
    setTabs(merge(tabs, storedTabs));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isFirstRender.current) {
      const filteredTabs = tabs.map((tab) => {
        return {
          id: tab.id,
          steps: tab.steps,
        };
      });
      setItem(localStorageKey, filteredTabs);
    } else {
      isFirstRender.current = false;
    }
  }, [currentUser, tabs, localStorageKey]);

  const commonProps = {
    dispatch,
    onBack,
    onContinue: onNextStep,
    activeTab,
    activeStep,
    accountId,
    employeeId,
  };

  const isNiceGoingActive = () => isActiveTab(last(tabs));

  return (
    <DefaultLayout
      numberOfParts={4}
      activeIndex={progressCircleIndex}
      showProgressCircle={activeTab.showInProgressTracker}
      logo={isNiceGoingActive() ? "white" : "default"}
      className={isNiceGoingActive() ? "bg-primary-dark_2" : ""}
      tabs={tabs}
      activeTab={tabs[tabIndx]}
    >
      <UserHijack />
      <ProgressTracker
        tabs={tabs}
        activeTab={tabs[tabIndx]}
        stepIndex={stepIndx}
        basePath={basePath}
        onBack={onBack}
        onForward={onForward}
        showNavbar={activeTab.showInProgressTracker}
      />
      <div className="flex flex-grow mx-auto mt-7 md:mt-8 w-full max-w-[54rem]">
        <Outlet
          context={{
            ...commonProps,
            data: currentUser,
            subtitle: t(
              "dashboard:dashboard.employee.onboarding.welcomePage.description"
            ),
            onPrimaryClick: () => navigate("/individual/main"),
            onSecondaryClick: showFunVideo,
          }}
        />
      </div>
    </DefaultLayout>
  );
}

export default EmployeeOnboarding;
