import { QueryClient, UseQueryOptions, useQuery } from "react-query";
import { openapi } from "~/services/openapi-client";
import { OpenApiFetchOptions, OpenApiQueryParams } from "~/types/openapi-params";

export const companiesQueryKey = ["companies"];

interface Params
  extends OpenApiQueryParams<"get", "/api/v1/admin/securities/performance"> {}

interface GetSecuritiesMetaProps extends Params {
  fetchOptions?: OpenApiFetchOptions<"get", "/api/v1/admin/securities/performance">;
}

export const fetchSecuritiesMeta = async ({
  skip,
  limit,
  securityId,
  fetchOptions = {},
  ...filters
}: GetSecuritiesMetaProps) => {
  const { data } = await openapi.get("/api/v1/admin/securities/performance", {
    ...fetchOptions,
    params: {
      query: {
        skip,
        limit,
        security_id: securityId,
        ...filters,
      },
    },
  });

  return data;
};

type UseSecuritiesMetaReturnValue = Awaited<ReturnType<typeof fetchSecuritiesMeta>>;

interface UseSecuritiesMetaProps extends Params {
  securityId?: number;
  queryOptions?: Omit<
    UseQueryOptions<UseSecuritiesMetaReturnValue, unknown, UseSecuritiesMetaReturnValue>,
    "queryKey" | "queryFn"
  >;
}

export const getSecuritiesMetaQueryKey = (...keys) => ["securitiesMetaData", ...keys];

export const useSecuritiesMeta = ({
  skip = 0,
  limit = 100,
  securityId = null,
  queryOptions = {},
  ...filters
}: UseSecuritiesMetaProps = {}) => {
  return useQuery({
    queryKey: getSecuritiesMetaQueryKey({ skip, limit, securityId, filters }),
    queryFn: () => fetchSecuritiesMeta({ skip, limit, securityId, ...filters }),
    keepPreviousData: true,
    ...queryOptions,
  });
};

interface FetchPerformanceRowProps {
  performanceId: string;
  fetchOptions?: OpenApiFetchOptions<
    "get",
    "/api/v1/admin/securities/performance/{performance_row_id}"
  >;
}

export const fetchPerformanceRow = async ({
  performanceId,
  fetchOptions = {},
}: FetchPerformanceRowProps) => {
  const { data } = await openapi.get(
    "/api/v1/admin/securities/performance/{performance_row_id}",
    {
      ...fetchOptions,
      params: { path: { performance_row_id: Number(performanceId) } },
    }
  );

  return data;
};

export const getSecurityMetaQueryKey = (...keys) => ["securityMeta", ...keys];

const singlePerformanceRowQuery = ({ performanceId }) => ({
  queryKey: getSecurityMetaQueryKey(performanceId),
  queryFn: () => fetchPerformanceRow({ performanceId }),
  enabled: !!performanceId,
});

export const useSecurityPerformanceRow = ({ performanceRowId }) => {
  return useQuery(singlePerformanceRowQuery({ performanceId: performanceRowId }));
};

export const securityPerformanceLoader = (queryClient: QueryClient) => {
  return async ({ params }) => {
    const query = singlePerformanceRowQuery({
      performanceId: params.performance_id,
    });

    return (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    );
  };
};
